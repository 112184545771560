import React, { ReactElement, useRef, useEffect } from 'react';
import { css } from '@emotion/react';
import * as Styled from './styles';

import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';
import { SpellLike } from '../../components/SpellLike/SpellLike';
import { breakpoints } from '../../styles/variables';
import { PRICING_DATA_LTD } from "../../data/pricing";
import { useLocation } from '@reach/router';
import './style.css'


const WORDS = ['ideas', 'flows', 'processes', 'plans', 'concepts', 'problems'];

interface DealIntroNewProps {
  showVideo: boolean;
}


export const DealIntroNew = ( { showVideo }: DealIntroNewProps ): ReactElement => {
  const illustrationsRef = useRef(null);
  const { isVisible } = useVisibilityDetection(illustrationsRef);
  const pricing = {PRICING_DATA_LTD}.PRICING_DATA_LTD
  const location = useLocation();


  useEffect(()=>{
    const deal_qstr =  window.location.href.split('?')[1];
    let link = "https://checkout.sketchwow.com?add-to-cart=407";
    let links = document.getElementsByClassName("deal-cart-link");
    if(location.pathname == '/sale/' || location.pathname == '/sale')
      link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1";
    
    if(typeof deal_qstr !== 'undefined' &&  links !== null){
      let i;
      for (i = 0; i < links.length; i++) {
        links[i].href = link + '&'  + deal_qstr;
      }
    }else if(links !== null){
      let i;
      for (i = 0; i < links.length; i++) {
        links[i].href = link;
      }
    }


    const script = document.createElement('script');
    script.src = 'https://embed.voomly.com/embed/embed-build.js';
    script.async=true;
    document.body.appendChild(script);

   }, []);

  return (
    <Styled.SafariWrapper>
      <Styled.Wrapper ref={illustrationsRef} isVisible={isVisible}>
        <Styled.Section>
          <Styled.Content>
            <Styled.Heading>
              <Styled.Title>
              Create fast, super-engaging simple sketches to clearly visualize
your{' '}
                <span
                  css={css`
                    display: inline-block;
                    position: relative;
                    z-index: 10;

                    @media screen and (max-width: ${breakpoints.md}px) {
                      margin-right: 0;
                    }
                  `}
                >
                  <SpellLike disabled={!isVisible} minWidth={130} words={WORDS} />
                </span>
              </Styled.Title>
              <Styled.SubTitle>And look like a HERO in front of your... clients, colleagues, community or class...</Styled.SubTitle>
            </Styled.Heading>


          <div id="mobile-displayNew">
            
            {showVideo &&
               <div className="voomly-embed" data-id="mhiRUWAGryy4ZkRNTlRiFrC-XAEScRC351QYAgs_ELLZQRsDW" data-ratio="1.777778" data-type="v"></div>
              }

              {!showVideo &&
              <Styled.Figure >
                     <img
                     src="/SW-video-demo2.gif"
                     srcSet="/SW-video-demo2.gif 1x, /SW-video-demo2.gif 2x"
                     alt="Illustration"
                     width={527}
                     loading="lazy"
                   />
                   </Styled.Figure>
               }



             
            

            <Styled.Badge css={css`z-index:11; position: relative;`}> </Styled.Badge>

            <div className="row-new-deal" css={css`height: 150px ; position: relative; z-index:10;`}>


                <div className='column-1' css={css`width: 72% !important;`}>
                <h3>Get SketchWow for a 1-Time Price</h3>
                <div>ZERO MONTHLY / ANNUAL FEES FOREVER</div>
                </div>
                    
                <div className='column-2' css={css`width: 22% !important;`}>
                  <h2 id="price-crossout"
                  css={css`font-size: 13px; color:#fff; margin-top:13px;`}
                  >$468/YEAR</h2>

                  <h1 css={css`color:#f7d701 !important; margin-top:0px;`}>NOW: {pricing[0].std}</h1>
                </div>

                <Styled.Button className="deal-cart-link" href="https://checkout.sketchwow.com?add-to-cart=407">Get SketchWow</Styled.Button>
                <br/>
                <br/>
                <Styled.AppInfo>Available For Windows & Mac computers</Styled.AppInfo>
            </div>
            <div className="row-new-deal">         
                <div className='column-1'>
                
                </div>
            </div>    
          </div>

          <div id="desktop-displayDSNew">
            <div id="desktop-displayNew">
            
              {showVideo &&
                <div className="voomly-embed" data-id="mhiRUWAGryy4ZkRNTlRiFrC-XAEScRC351QYAgs_ELLZQRsDW" data-ratio="1.777778" data-type="v"></div>
              }

              {!showVideo &&
                  <Styled.Figure id="desktop-displayNew">
                      <img
                        src="/SW-video-demo2.gif"
                        srcSet="/SW-video-demo2.gif 1x, /SW-video-demo2.gif 2x"
                        alt="Illustration"
                        loading="lazy"
                      />
                    </Styled.Figure>
               }


            
            </div>
 

            <div className="row-new-deal">


                <div className='column-1'>
                <h3>Get SketchWow for a 1-Time Price</h3>
                <div>ZERO MONTHLY / ANNUAL FEES FOREVER</div>
                </div>

                <div className='column-2'>
                  <h2 id="price-crossout"
                  css={css`font-size: 15px; color:#fff; margin-top:13px;`}
                  >$468/YEAR</h2>

                  <h1 css={css`color:#f7d701 !important; margin-top:5px;`}>NOW: {pricing[0].std}</h1>
                </div>
              

                <div className='column-3'>
                <Styled.Button className="deal-cart-link" href="https://checkout.sketchwow.com?add-to-cart=407">Get SketchWow</Styled.Button>
                </div>
            </div>        

            <div className='badgeContainer' >
              
              <Styled.Badge> </Styled.Badge>
            
              </div>  
    
              <Styled.AppInfo className="availableFor">Available For Windows & Mac computers</Styled.AppInfo>

            </div>

            
          </Styled.Content>





        </Styled.Section>
      </Styled.Wrapper>

    </Styled.SafariWrapper>
  );
};
